
export default {
  name: 'CheckboxItem',
  props: {
    value: {
      type: [String, Number, Array],
      required: true
    },
    nativeValue: {
      type: [String, Number],
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'checkbox',
      required: false
    },
    noIcon: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: '',
      required: false
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    text: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    isArray() {
      return Array.isArray(this.value)
    },
    checked() {
      return this.isArray
        ? this.value.includes(this.nativeValue)
        : this.value === this.nativeValue
    }
  },
  methods: {
    click() {
      this.$refs.input?.focus()
    },
    keydown() {
      this.$refs.label?.click()
    },
    change() {
      if (this.isArray) {
        let value
        if (this.type === 'radio') {
          value = [this.nativeValue]
        } else {
          value = [...this.value]
          if (value.includes(this.nativeValue)) {
            value.splice(value.indexOf(this.nativeValue), 1)
          } else {
            value.push(this.nativeValue)
          }
        }
        this.$emit('input', value)
      } else {
        this.$emit('input', this.nativeValue)
      }
    }
  }
}
